import axios from "axios"

const apiClient = axios.create( {
  baseURL: "https://" + ( process.env.VUE_APP_STAGE === "prod" ? "" : "dev" ) + "api.aelab.app/",
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
} )

export { apiClient }